import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
    offset: 200,       // Mayor distancia para iniciar la animación
    duration: 600,     // Duración más larga para animaciones suaves
    easing: "ease-in-out", // Transición más fluida
    delay: 0,         // Menor retraso inicial para un mejor flujo visual
    once: true,        // Animar solo una vez al entrar en pantalla
    anchorPlacement: "top-bottom", // Anima al entrar desde la parte inferior
});
